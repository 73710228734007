export const KATEGORIESEITE_GELADEN_EVENT = "kategorieseite-geladen";

class KategorieInfo extends HTMLElement {
    connectedCallback() {
        const gueltigeKonfiguration = this.istGueltigeKonfiguration();
        if (!gueltigeKonfiguration) return;
        this.dispatchEvent(new CustomEvent("kategorieseite-geladen", {
            bubbles: true,
            detail: {
                kategorieLabel: this.kategorieLabel,
                kategorieSuchparameter: this.getKategorieSuchparameter()
            }
        })); 
    }

    istGueltigeKonfiguration() {
        if (this.kategorieLabel === undefined || this.kategorieLabel === '') return false;
        if (this.kategorieSuchparameter === undefined || this.kategorieSuchparameter === '') return false;
        return true;
    }

    getKategorieSuchparameter() {
        let suchparameter = [];
        const url = new URL(window.location.origin + '?' + this.kategorieSuchparameter);
        const searchparams = new URLSearchParams(url.search);
        for (const param of searchparams) {
            suchparameter.push(
                Object.assign({}, {
                    name: param[0],
                    value: param[1]
                })
            )
        }
        return suchparameter;
    }

    get kategorieLabel() {
        return this.dataset['kategorieLabel'];
    }

    get kategorieSuchparameter() {
        const kategorieSuchparameter = this.dataset['kategorieSuchparameter'];
        if (kategorieSuchparameter.indexOf("?") === -1) return kategorieSuchparameter;
        return kategorieSuchparameter.replace("?", "");
    }
}

if (!customElements.get("kategorie-info")) {
    customElements.define("kategorie-info", KategorieInfo);
}
